import React from 'react';
import img1 from "../../images/Footwear 6.webp";
import img2 from "../../images/Footwear 7.webp";
import img3 from "../../images/Footwear 8.webp";
import './utilise.css';

export default function Package() {
	return (
		<div className="container utilise-content" id="id_utilise_content">
            <h2>How the Footwear Industry leverages <span>Brandwise</span> . . .</h2>
        <div className="row">
            <div className="col-md-4 order-md-2">
            <div className='wrapper p-4'>
                <div className="footwear-content3">
                <img src={img1} alt="Women going on a shopping spree excited for more experiences, offers and sales  " 
                loading='lazy' style={{marginLeft:'-25px'}} />
                    <h3>Drive Store Footfall to New Heights</h3>
                    <p>Utilize proven 16+ dynamic
                       engagement channels to boost
                       store footfall and enhance
                       conversion rates. seamlessly
                       integrate offline-to-online (O2O),
                       bring diverse visitors from wider
                       platforms. Deliver and Accept
                       omnichannel coupons, measure
                       growth performance</p>
                </div>
                </div>
            </div>
            
            <div className="col-md-4 order-md-2">
            <div className='wrapper p-4'>
                <div className="footwear-content3">
                <img src={img2} alt="Customer enjoying seamless omnichannel experiences, empowering brands to build strong connections 
                and drive loyalty across platforms" loading='lazy' style={{marginTop:'-5px',marginLeft:'-18px'}} />
                    <h3>One Stop Platform for All Needs</h3>
                   <p>Brandwise not only has rich set
                      of tools, but it also comes with
                      pre-loaded ecosystem
                      integrations such as POS,
                      Shopify, CRM, WhatsApp, Meta
                      campaigns and more.
                      The unified platform, relives you
                      from pinpoints & let you focus
                      on your core business</p><br/>
                </div>
                </div>
            </div>
            <div className="col-md-4 order-md-2">
            <div className='wrapper p-4'>
                <div className="footwear-content3">
                <img src={img3} alt="Business executive excited about tracking down and addressing counterfeit products, 
                safeguarding his brand from loss of revenue." loading='lazy' style={{marginLeft:'-18px'}} />
                    <h3>Counterfeit Protection</h3>
                    <p>Utilize advanced product
                       authentication to safeguard your
                       brand and customers against
                       counterfeit products. Ensure the
                       integrity and trustworthiness of
                       your products and avoid revenue
                       losses</p><br/><br/><br/><br/>
                </div>
            </div>
            </div>
        </div>
        <div id="feedbackContent"></div><br /><br /> <br />
        </div>
    )
}