import React, {useEffect}from 'react';
import TopHead from "../components/web3.0/topHead";
import WebPlatfrom1 from '../components/web3.0/webplatform1';
import WebPlatfrom2 from '../components/web3.0/webplatform2';
import WebPlatfrom3 from '../components/web3.0/webplatform3';
import WebPlatfrom4 from '../components/web3.0/webplatform4';
import WebPlatfrom5 from '../components/web3.0/webplatform5';
import WebPlatfrom6 from '../components/web3.0/webplatform6';
import Webslider from '../components/web3.0/webslider';
import WebSubscribe from '../components/web3.0/websubscribe';



function Web3() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
    return (
        <div>
          <TopHead></TopHead>
          <WebPlatfrom1></WebPlatfrom1>
          <WebPlatfrom2></WebPlatfrom2>
          <WebPlatfrom3></WebPlatfrom3>
          <WebPlatfrom4></WebPlatfrom4>
          <WebPlatfrom5></WebPlatfrom5>
          <Webslider></Webslider>
          <WebPlatfrom6></WebPlatfrom6>
          <WebSubscribe></WebSubscribe>
        </div>
    )
}
export default Web3;