import React from 'react';
import img1 from "../../images/Footwear 6.webp";
import img2 from "../../images/Footwear 7.webp";
import img3 from "../../images/retail8.webp";
import './brand.css';

export default function Brand() {
	return (
		<div className="container brand-content" id="brandContent">
            <h2>How the Footwear Industry leverages <span>Brandwise</span> . . .</h2>
        <div className="row">
            <div className="col-md-4 order-md-2">
            <div className='wrapper p-4'>
                <div className="retail-content3">
                <img src={img1} alt="Women going on a shopping spree excited for more 
                experiences, offers and sales" style={{marginLeft:"-30px",marginTop:"-30px",width:"118%"}} />
                    <h2>Drive Store Footfall to New Heights</h2>
                    <p>Utilize proven 16+ dynamic
                       engagement channels to boost
                       store footfall and enhance
                       conversion rates. seamlessly
                       integrate offline-to-online (O2O),
                       bring diverse visitors from wider
                       platforms. Deliver and Accept
                       omnichannel coupons, measure
                       growth performance</p>
                </div>
                </div>
            </div>
            
            <div className="col-md-4 order-md-2">
            <div className='wrapper p-4'>
                <div className="retail-content3">
                <img src={img2} alt="Customer enjoying seamless omnichannel experiences, empowering brands to 
                build strong connections and drive loyalty across platforms"  />
                    <h2>One Stop Platform for All Needs</h2>
                   <p>Brandwise not only has rich set
                      of tools, but it also comes with
                      pre-loaded ecosystem
                      integrations such as POS,
                      Shopify, CRM, WhatsApp, Meta
                      campaigns and more.
                      The unified platform, relives you
                      from pinpoints & let you focus
                      on your core business</p><br/>
                </div>
                </div>
            </div>
            <div className="col-md-4 order-md-2">
            <div className='wrapper p-4'>
                <div className="retail-content3">
                <img src={img3} alt="Customer feeling secure about trusting company with their 
                data for improved customer experiences"  />
                    <h2>Build Trust and Collect Data...Safely</h2>
                    <p>Create appealing and
                       inspiring interactions to collect
                       user data exclusive to your
                       business and services. Make
                       customers part of your journey
                       and build responsible data
                       assets to empower future
                       actions.</p><br/><br/><br/><br/>
                </div>
            </div>
            </div>
        </div>
        <div id="feedbackContent"></div><br /><br /> <br />
        </div>
    )
}