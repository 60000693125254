import React, { useState, useEffect, useRef } from "react";
import './talkbusiness.css';
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import img1 from "../../images/captcha_reload.png";

export default function TalkBusiness() {
    const [first_name, setFirstname] = useState("");
    const [last_name, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [resume, setResume] = useState("");
    const [error, setError] = useState(false);
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [captchaText, setCaptchaText] = useState(''); 
    const [userInput, setUserInput] = useState(true); 
    const canvasRef = useRef(null); 
    const [captchaValid, setCaptchaValid] = useState(true);


    //Generating captcha
    useEffect(() => { 
        const canvas = canvasRef.current; 
        const ctx = canvas.getContext('2d'); 
        initializeCaptcha(ctx); 
    }, []); 
  
    const generateRandomChar = (min, max) => 
    String.fromCharCode(Math.floor(Math.random() * (max - min + 1) + min)); 

    const generateCaptchaText = () => { 
       let captcha = ''; 
       for (let i = 0; i < 4; i++) { 
          captcha += generateRandomChar(65, 90); // for uppercase letters
          captcha += generateRandomChar(97, 122); // for lowercase letters
          captcha += generateRandomChar(48, 57); // for digits
    } 
    return captcha.split('').sort(() => Math.random() - 0.5).join('').substring(0, 4); 
    }; 

    console.log(generateCaptchaText()); 

    const drawCaptchaOnCanvas = (ctx, captcha) => { 
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height); 
        const textColors = ['rgb(0,0,0)', 'rgb(130,130,130)']; 
        const letterSpace = 150 / captcha.length; 
        for (let i = 0; i < captcha.length; i++) { 
            const xInitialSpace = 25; 
            ctx.font = '20px Roboto Mono'; 
            ctx.fillStyle = textColors[Math.floor( 
                Math.random() * 2)]; 
            ctx.fillText( 
                captcha[i], 
                xInitialSpace + i * letterSpace, 
                  
                // Randomize Y position slightly 
                Math.floor(Math.random() * 16 + 25), 
                100 
            ); 
        } 
    }; 
  
    const initializeCaptcha = (ctx) => { 
        setUserInput(''); 
        const newCaptcha = generateCaptchaText(); 
        setCaptchaText(newCaptcha); 
        drawCaptchaOnCanvas(ctx, newCaptcha); 
    }; 
  
    const handleUserInputChange = (e) => { 
        setUserInput(e.target.value); 
    }; 

    async function careers() {
        const formData = new FormData()
        formData.append('first_name', first_name)
        formData.append('last_name', last_name)
        formData.append('phone', phone)
        formData.append('email', email)
        formData.append('message', message)
        formData.append('resume', resume)
        formData.append('userInput', userInput)
        if (first_name.trim("").length === 0 || last_name.trim("").length === 0 || email.trim("").length === 0 || phone.trim("").length === 0 || resume.length === 0 ) {
            setError(true)
        } 
        else if (userInput === captchaText) {
            setError(false)
            setCaptchaValid(true);
            setIsLoading(true);
            fetch("https://web.ciphercode.co/api/v1/career_form/", {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(response => {
                if (response.message === "Your data successfully submitted") {
                    Swal.fire({title: "Thanks for applying!",text: "We'll review your application and be in touch soon.",icon: "success",timer: 5000});
                    setFirstname("")
                    setLastname("")
                    setEmail("")
                    setPhone("")
                    setResume("")
                    setMessage("")
                    setUserInput("")
                }
            }).catch((error) => {
                Swal.fire({title: "Error!",text: "An error occurred. Please try again later.",icon: "error",timer: 5000});     
            }).finally(() => {
                setIsLoading(false);
            })
        }else {
            setError(true);
            setCaptchaValid(false); // Set captcha validity state to false
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            initializeCaptcha(ctx);
        }
        
    }
    return (
        <LoadingOverlay active={isLoading} spinner text='Submitting...'>
            <div style={{ textAlign: '-webkit-center' }}>
                <div className="container talkbusines">
                    <h1>Join Ciphercode</h1>
                    <p className="px-6">Join the team of skilled, talented, insightful, professional and great minds. We are always looking for talent, so don't hesitate to contact us.</p>
                    <div className="talkbusbox">
                        <div className="row">
                            <div className="col">
                                <div className="contactus">
                                    <h3>Call us</h3>
                                    <h6><FiPhoneCall /> &nbsp; +91-9741111594</h6>
                                    <h3>Email us</h3>
                                    <h6><MdOutlineMail /> &nbsp; info@ciphercode.co</h6>
                                </div>
                            </div>
                            <div className="col">
                                <div className="contactform">
                                    <h2>Contact Form</h2>
                                    <form action="">
                                        <div className="row px-3">
                                            {error && <p style={{ color: 'red' }}>Please, fill out all the required fields</p>}
                                            <div className="col">
                                                <label className="mb-1 required">First Name</label>
                                                <input type="text" className="mb-4" name="fname" value={first_name} placeholder="Enter first name" required onChange={(e) => setFirstname(e.target.value)} />
                                            </div>
                                            <div className="col">
                                                <label className="mb-1 required">Last Name</label>
                                                <input type="text" className="mb-4" name="lname" value={last_name} placeholder="Enter last name" required onChange={(e) => setLastname(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="row px-3">
                                            <div className="col">
                                                <label className="mb-1 required">Email</label>
                                                <input className="mb-4" type="email" name="email" value={email} placeholder="Enter valid email address" required onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                            <div className="col">
                                                <label className="mb-1 required">Phone No.</label>
                                                <input className="mb-4" type="number" name="phone" placeholder="Enter phone number" value={phone} required onChange={(e) => setPhone(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="row px-3">
                                            <label className="mb-1">Message</label>
                                            <textarea type="text" className="mb-3" id="message" name="message" value={message} onChange={(e) => setMessage(e.target.value)} />
                                        </div>
                                        <div className="row px-3">
                                            <label className="mb-1 required">Upload Resume</label>
                                            <input type="file" className="mb-4" id="exampleFormControlFile1" required onChange={(e) => setResume(e.target.files[0])} />
                                        </div>
                                        <div className="captcha-field">
                                          <div className="row px-4 form-item">

                                          <input 
                                            type="text"
                                            id="user-input"
                                            placeholder="Enter the Captcha"
                                            value={userInput} 
                                            onChange={handleUserInputChange}
                                            className={captchaValid ? "" : "invalid"}/> 
                                          </div>
                                        <div className="captcha-wrapper">
                                    
                                          <canvas className="captcha-box" ref={canvasRef} 
                                            width="200"
                                            height="70" > 
                                          </canvas> 
                                          <button 
                                            id="captcha-reload-button" 
                                            type="button" // Add this line to prevent form submission
                                            onClick={() => initializeCaptcha(canvasRef.current.getContext('2d'))} 
                                            style={{ border: "none", marginLeft: "20px", background: "none", verticalAlign: "middle" }} 
                                          >
                                           <img src={img1} alt='captcha' className="reload-img" />
                                          </button>
                                    
                                    </div>
                                </div>
                                        <div className="row px-3">
                                            <button type="button" id="button" name="button" onClick={careers}>SEND REQUEST</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    )
}