import React, { useEffect } from "react";
import Navbar from "./components/Navbar/navbar";
import Home from "./pages/home";
import Resources from "./pages/resources";
import Blog from "./pages/blog";
import BlogPost from "./pages/blogpost";
import BlogPost2 from "./pages/blogpost2";
import BlogPost3 from "./pages/blogpost3";
import BlogPost4 from "./pages/blogpost4";
import BlogPost5 from "./pages/blogpost5";
import BlogPost6 from "./pages/blogpost6";
import BlogPost7 from "./pages/blogpost7";
import BlogPost8 from "./pages/blogpost8";
import Partnership from "./pages/partnership";
import ArticlePost from "./pages/articlepost";
import Product from "./pages/product";
import Contactus from "./pages/contactus";
import About from "./pages/aboutus";
import Register from "./pages/signup";
import QsealPage from "./pages/qSeal";
import QreachPage from "./pages/qReach";
import LocationQR from "./components/QRCode/location_qr";
import WebformQR from "./components/QRCode/webforms";
import QReportPage from "./pages/qReport";
import FooterNewInfra from "./components/ContactUs/footerNewInfra";
import URlQR from "./components/QRCode/url_qr";
import WhatsAppQR from "./components/QRCode/whatsapp";
import SocialMedia from "./components/QRCode/social_media";
import PDFQR from "./components/QRCode/file_qr";
import CampaignQR from "./components/QRCode/campaign";
import ShortURL from "./components/QRCode/shorturl";
import FourOFourpage from "./pages/404page";
import TermsCondition from "./pages/terms&conditions";
import {Route, Routes} from 'react-router-dom';
import HomeApplicants from "./pages/homeapplicants";
import AutoMobile from "./pages/automobile";
import Web3 from "./pages/web3";
import CareerPage from "./pages/careers";
import PersonalCarePage from "./pages/personalcare";
import Sitemap from "./pages/sitemap";
import BrandSom from "./pages/bransdsom";
import Packaging from "./pages/packaging";
import Footwear from "./pages/footwear";
import ScheduleForm from "./components/Login/scheduleDemo";
import CookieConsent from "react-cookie-consent";
import { BsWhatsapp} from "react-icons/bs";
import NotFound from "./pages/404page";
import Retail from "./pages/retail";
import Onboarding from "./pages/onboarding";




function App() {
  useEffect(() => {
    const useCaseMenu = document.getElementById("useCaseMenu");
    const useCase = document.getElementById("use-case");
    useCaseMenu.classList.add("hide");
    function openUseCaseDropdown() {
      useCaseMenu.classList.remove("hide");
    }
    function closeUseCaseDropdown() {
      useCaseMenu.classList.add("hide");
    }
    useCase.onmouseenter = openUseCaseDropdown;
    useCase.onmouseleave = closeUseCaseDropdown;
    useCaseMenu.onmouseenter = openUseCaseDropdown;
    useCaseMenu.onmouseleave = closeUseCaseDropdown;
    useCaseMenu.onclick = closeUseCaseDropdown;
  });
  return (
    <div className="App">
        <Navbar />
         <Routes>
          <Route path="/" element={<Home></Home>}></Route> 
          <Route path="/resources" element={<Resources></Resources>}></Route>
          <Route path="/blog" element={<Blog></Blog>}></Route>
          <Route path="/product_digitization" element={<BlogPost></BlogPost>}></Route>
          <Route path="/cryptoblock_qr" element={<BlogPost2></BlogPost2>}></Route>
          <Route path="/cryptoblock_anti-counterfeit" element={<BlogPost3></BlogPost3>}></Route>
          <Route path="/encryption" element={<ArticlePost></ArticlePost>}></Route>
          <Route path="/nft_staking" element={<BlogPost4></BlogPost4>}></Route>
          <Route path="/first_party" element={<BlogPost5></BlogPost5>}></Route>
          <Route path="/navigating_the_future" element={<BlogPost6></BlogPost6>}></Route>
          <Route path="/Vanity_metrics_to_Revenue_Metrics" element={<BlogPost7></BlogPost7>}></Route>
          <Route path="/information_to_intelligence" element={<BlogPost8></BlogPost8>}></Route>
          <Route path="/product" element={<Product></Product>}></Route>
          <Route path="/contactus" element={<Contactus></Contactus>}></Route>
          <Route path="/aboutus" element={<About></About>}></Route>
          <Route path="/homeapplicances" element={<HomeApplicants></HomeApplicants>}></Route>
          <Route path="/automobile" element={<AutoMobile></AutoMobile>}></Route>
          <Route path="/personalcare" element={<PersonalCarePage></PersonalCarePage>}></Route>
          <Route path="/partnershipprogram" element={<Partnership></Partnership>}></Route>
          <Route path="/signup" element={<Register></Register>}></Route>
          <Route path="/product/qSeal" element={<QsealPage></QsealPage>}></Route>
          <Route path = "/product/qReport" element={<QReportPage></QReportPage>}></Route>
          <Route path="/career" element={<CareerPage></CareerPage>}></Route>
          <Route path="product/qReach"element={<QreachPage></QreachPage>}></Route>
          <Route path="404" element={<FourOFourpage></FourOFourpage>}></Route>
          <Route path="/privacy" element={<TermsCondition></TermsCondition>}></Route>
          <Route path="/qrcode/url" element={<URlQR />}></Route>
          <Route path="/footwear" element={<Footwear></Footwear>}></Route>
          <Route path="/retail" element={<Retail></Retail>}></Route>
          <Route path="/packaging" element={<Packaging></Packaging>}></Route>
          <Route path="/qrcode/whatsapp" element={<WhatsAppQR />}></Route>
          <Route path="/qrcode/social_meida" element={<SocialMedia />}></Route>
          <Route path="/qrcode/File_qr" element={<PDFQR />}></Route>
          <Route path="/qrcode/location_qr" element={<LocationQR />}></Route>
          <Route path="/qrcode/webform_qr" element={<WebformQR />}></Route>
          <Route path="/qrcode/campaign" element={<CampaignQR />}></Route>
          <Route path="/web_3.0" element={<Web3 />}></Route>
          <Route path="/qrcode/shorturl" element={<ShortURL></ShortURL>}></Route>
          <Route path="/sitemap" element={<Sitemap></Sitemap>}></Route>
          <Route path="/schedule_demo" element={<ScheduleForm></ScheduleForm>}></Route>
          <Route path="/signup_create" element={<Onboarding></Onboarding>}></Route>
          <Route path="product/brandsom/privacypolicy" element={<BrandSom></BrandSom>}></Route> 
          <Route path="*" element={<NotFound></NotFound>}></Route>
        </Routes>
      <FooterNewInfra />
      <a href="https://wa.me/919741111594?text=Hello,%20I'm%20interested%20to%20know%20more%20about%20your%20products." className="whatsapp_float" target="_blank" rel="noopener noreferrer">
        <BsWhatsapp />
      </a>
      <CookieConsent  location="bottom" buttonText="Accept All" cookieName="myAwesomeCookieName2" expires={100} style={{ background: "#61C4FD", fontSize: "17px"}}
        buttonStyle={{ color: "#4e503b", fontSize: "17px", height: "45px"}}>We use cookies to give you best online experience using analytics, marketing tools and anonymous data. By continuing further you consent <br />to our privacy policy. For more information, read our <a href="/privacy">privacy policy.</a>
      </CookieConsent>
    </div>
  );
}
export default App;
